<template>

  <v-form v-if="!_IsEmpty(makes)" autocomplete="off" name="carFilterForm" method="get"
          @submit.prevent="submitFilter">
    <div class="form_row d-flex justify-between align-items-center" v-show="makes">
      <div class="form_input d-flex justify-start align-items-center big-svg">

        <VAutocomplete
            v-model="selectedProduct.make"
            :items="makes"
            variant="underlined"
            :key="`autocomplete-make-${autocompleteKey}-${selectedProduct.make}`"
            :placeholder="'יצרן'"
            :custom-filter="customFilter"
            name="make"
            item-title="text"
            item-value="value"
            hide-details
            :persistent-clear="true"
            :auto-select-first="true"
            :readonly="isMobile"
            @click="mobileSelect('make')"
            @focus="selectAllText"
            :no-data-text="noDataText"
            autocomplete="off"
            @input="inputMake"

        >

          <template #prepend>
            <img width="64px"
                 fit="contain"
                 src="/images/icons/car.png.webp"
                 alt="אייקון המסמל בחירת יצרן הרכב" />
          </template>

          <template v-slot:item="{ props, item }">

            <v-list-item
                v-bind="props"
                :height="20"
                class="filter-item font-weight-bold"
                :value="item.value"
                color="black"
            >
              <template #prepend>
                <nuxt-img :src="item.raw.logo" width="30" class="ml-2" height="40"  fit="contain"/>
              </template>
              <template #title="{ title }">
                <div v-html="title"></div>
              </template>
            </v-list-item>
          </template>
          <template #selection="{ item }">
              <nuxt-img :src="item.raw.logo" width="30" class="ml-2" height="40"  fit="contain"/>
              <span class="v-autocomplete__selection-text" v-html="item.title"></span>
          </template>
        </VAutocomplete>
      </div>

      <div
          class="form_input form_select d-flex justify-content-start align-items-center"
          :class="{ disabled: _IsEmpty(selectedProduct.make) }"
          :key="`autocomplete-model-${autocompleteKey}-${selectedProduct.make}`"
      >
        <VAutocomplete
            v-model="selectedProduct.year"
            :items="years"
            :key="`autocomplete-model-${autocompleteKey}-${selectedProduct.make}`"
            variant="underlined"
            name="year"
            :placeholder="'שנה'"
            :custom-filter="customFilter"
            :auto-select-first="true"
            item-title="text"
            item-value="value"
            @focus="selectAllText"
            :readonly="isMobile"
            @click="mobileSelect('year')"
            hide-details
            :no-data-text="noDataText"
            autocomplete="off"
        >
          <template #prepend>
            <img fit="contain"
                 src="/images/icons/calendar.png.webp"
                 width="38px"
                 alt="אייקון המסמל אפשרות בחירה של שנת הרכב בפילטר ראשי של בחירת רכב הלקוח" />
          </template>
        </VAutocomplete>
      </div>
      <div
          class="form_input form_select d-flex justify-start align-items-center"
          :class="{ disabled: _IsEmpty(selectedProduct.year) }"
          :key="`autocomplete-${autocompleteKey}-${selectedProduct.year}`"

      >
        <VAutocomplete
            v-model="selectedProduct.model"
            ref="modelSelect"
            :items="models"
            :key="`autocomplete-${autocompleteKey}-${selectedProduct.year}`"
            variant="underlined"
            :custom-filter="customFilter"
            item-title="text"
            item-value="value"
            :auto-select-first="true"
            @click="mobileSelect('model')"
            hide-details
            :readonly="isMobile"
            :placeholder="'דגם'"
            :no-data-text="noDataText"
            autocomplete="off"
            @focus="selectAllText"
        >
          <template #prepend>
            <img fit="contain"
                 loading="lazy"
                 width="34px"
                 alt="אייקון המסמל אפשרות בחירה של דגם הרכב בפילטר ראשי של בחירת רכב הלקוח"
                 src="/images/icons/steering_wheel.png.webp" />

          </template>
        </VAutocomplete>
      </div>

      <div class="bt_red form_submit btn-txt--big">
        <VBtn flat :loading="waitForQuery" width="210" :height="!isMobile ? 56: 'auto'" type="submit">התאימו לי שטיח
        </VBtn>
      </div>
    </div>
    <input type="hidden" name="action" value="findCar"/>
  </v-form>
  <v-skeleton-loader v-else :height="50" />
  <van-popup teleport="body" v-model:show="showPop" z-index="99999" position="bottom">
    <van-cascader
        v-model="cascaderValue"
        active-color="#ee0a24"
        :options="mobileSelectMakes"
        :placeholder="placeholder"
        @close="showPop = false"
        @finish="onFinish"
        @change="onChange"
    >
      <template #title>
        בחר סוג רכב
        <div class="help-cascader">

            <a href="javascript:void(0)" @click.prevent="showPopForm"
               class="d-flex align-items-center ">
              אצטרך עזרה במציאת הרכב שלי
            </a>
        </div>
      </template>
      <template #options-top="{ tabIndex }" v-if="false">
        <div class="level-title" v-if="tabIndex == 0">בחר יצרן</div>
        <div class="level-title" v-if="tabIndex == 1">בחר שנה</div>
        <div class="level-title" v-if="tabIndex == 2">בחר דגם</div>
      </template>
      <template #option="{option}">
        <span  v-html="option.text"></span>
        <nuxt-img class="ml-2"  rel="noindex" v-if="option.logo" :src="option.logo" />
      </template>
      <template #options-bottom="{tabIndex}">


      </template>
    </van-cascader>
  </van-popup>

  <client-only>
    <LazyPopupFilter @update:show="filterClosed" @finish="finishFilter" :show="showFilter"/>
  </client-only>
</template>
<script setup lang="ts">
const {
  carFilter,
  matSelectUri,
  setQueriedProducts,
  setCarFilterPopup,
  carsFilter3d,
  currentPage
} = useGlobalStore();
const {queriedProducts, filterPop, filterPopAutoSubmit, removedFromQuery, selectedProduct} = toRefs(useGlobalStore());
const {$activeModalsBus} = useNuxtApp();
const props = defineProps({
  autoSubmit: {
    type: Boolean,
    required: false,
  },
});
const modelSelect = ref(null);
const router = useRouter();
const isMobile = ref(false);
const showFilter = ref(false);
const showPop = ref(false);
const waitForQuery = ref(false);
const cascaderValue = ref("");
const noDataText = ref("לא נמצאו תוצאות");
const placeholder = ref("יצרן");
const showForm = ref(true);
const searchMakeInput = ref('');
const autocompleteKey = ref(0);
const pageLoaded = ref(false);
const selectedProductState = useState('selectedProduct', () => selectedProduct.value);
function forceRerender() {
  autocompleteKey.value++; // Increment the key to force re-render
}
function showPopForm() {
  showPop.value = false;
  $activeModalsBus.event('openPopup');
}
onUpdated(() => {
  if (is3dPage.value) {
    selectedProduct.value.make = null;
    selectedProduct.value.year = null
    selectedProduct.value.model = null;
  }
})
onMounted(() => {
  selectedProduct.value = selectedProductState.value;
  if (process.client) {
    isMobile.value = window.innerWidth < 800;
    cascaderValue.value = selectedProduct.value.model || selectedProduct.value.year || selectedProduct.value.make;
  }
  if (props.autoSubmit) {
    //submitFilter();
    filterPopAutoSubmit.value = false;
  }
  if (is3dPage.value) {
    selectedProduct.value.make = null;
    selectedProduct.value.year = null
    selectedProduct.value.model = null;
  }
  pageLoaded.value = true;
  forceRerender();
})
const is3dPage = computed(() => {
  return currentPage?.uri == '/3d/';
});

watch(
    () => selectedProduct.value.make,
    (value, oldValue) => {
      if (value) {
        selectedProduct.value.year = null;
        selectedProduct.value.model = null;

        setQueriedProducts([]);
        removedFromQuery.value = [];
      }
    }
);
watch(
    () => selectedProduct.value.year,
    (value) => {
      if (value) {
        selectedProduct.value.model = null;
        setQueriedProducts([]);
        removedFromQuery.value = [];
      }
    }
);
watch(
    () => selectedProduct.value.model,
    (value, oldValue) => {
      if (value) {
        findCar().then((data) => {
          setQueriedProducts(data?.value?.products.nodes);
          removedFromQuery.value = [];
          useAsyncGql("getCarBySku", {
            sku1: "01-" + queriedProducts.value[0].sku.split("-")[1],
            sku2: "02-" + queriedProducts.value[0].sku.split("-")[1],
            sku3: "03-" + queriedProducts.value[0].sku.split("-")[1],
            sku4: "04-" + queriedProducts.value[0].sku.split("-")[1],
            sku5: "05-" + queriedProducts.value[0].sku.split("-")[1],
          });

        });
      }
    }
);
const inputMake = (event) => {
  if (event.inputType == "deleteContentBackward") {
    selectedProduct.value.year = null;
    selectedProduct.value.model = null;
    setQueriedProducts([]);
    removedFromQuery.value = [];
  }

}
const selectAllText = (event) => {
  setTimeout(() => {
    event.target.select();
  }, 100);
};

function getEntriesForSelect(object): array {
  if (_IsEmpty(object)) return null;
  return _OrderBy(
      Object.entries(object).map(([key, value]) => {
        return {
          value: key,
          text: String(value?.name || value),
          children: getEntriesForSelect(value?.models) || null,
        };
      }), ['text']);
}

const decodeHtmlEntities = (str) => {
  if (process.client) {

    var txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
  } else {
    return str;

  }
}
const onFinish = ({selectedOptions}) => {
  showPop.value = false;
  if (isMobile.value && process.client) {
    const homefilter = document.querySelector('.car-filter-wrap');
    const sticky = homefilter?.querySelector('.van-sticky');
    if (sticky.classList.contains('van-sticky--fixed')) {
      setCarFilterPopup(true);
    }

    //submitFilter();
  }
};

const onChange = ({value, tabIndex}) => {

  if (tabIndex == 0) {
    selectedProduct.value.make = value;
    // selectedProduct.value.year = null;
    // selectedProduct.value.model = null;
    placeholder.value = "שנה";
  }
  if (tabIndex == 1) {
    selectedProduct.value.year = value;
    // selectedProduct.value.model = null;
    placeholder.value = "דגם";
  }
  if (tabIndex == 2) {
    selectedProduct.value.model = value;
  }
};
const mobileSelectMakes = computed(() => {
  const filter = currentPage?.uri == '/3d/' ? _CloneDeep(carsFilter3d) : _CloneDeep(carFilter);
  if (_IsEmpty(filter)) {
    return [];
  }
  let entries = _OrderBy(Object.entries(filter).map(([key, value]) => {
    return {
      value: key,
      logo: value?.logo,
      text: String(decodeHtmlEntities(value?.name)),
      children: _SortBy(getEntriesForSelect(value?.years), ["text"]).reverse(),
    };
  }), ['text']);

  return entries;
});


const makes = computed(() => {
  const filter = currentPage?.uri == '/3d/' ? _CloneDeep(carsFilter3d) : _CloneDeep(carFilter);
  if (_IsEmpty(filter)) {
    return [];
  }
  return _OrderBy(
      Object.entries(filter).map(([key, value]) => {
        return {
          id: key,
          value: key,
          logo: value?.logo,
          text: value?.name,
          years: value?.years,
        };
      }),
      ["text"]
  );
});
const years = computed(() => {
  const filter = currentPage?.uri == '/3d/' ? _CloneDeep(carsFilter3d) : _CloneDeep(carFilter);

  const selected_make_id: any = selectedProduct.value.make;
  if (_IsEmpty(filter) || _IsEmpty(selected_make_id)) {
    return [];
  }
  const yearsObject: Data = filter[selected_make_id]?.years;
  if (_IsEmpty(yearsObject)) {
    return [];
  }
  return _OrderBy(
      Object.entries(yearsObject).map(([key, value]) => {
        return {
          id: key,
          value: key,
          text: value?.name,
          models: value?.models,
        };
      }),
      ["text"],
      ["desc"]
  );
});

const models = computed(() => {
  const filter = currentPage?.uri == '/3d/' ? _CloneDeep(carsFilter3d) : _CloneDeep(carFilter);

  const selected_year_id: any = selectedProduct.value.year;
  const selected_make_id: any = selectedProduct.value.make;
  if (_IsEmpty(selected_year_id)) {
    return [];
  }

  const modelsObject = filter[selected_make_id]?.years[selected_year_id].models || {};
  if (_IsEmpty(modelsObject)) {
    return [];
  }
  const model_sort =  filter[selected_make_id]?.model_sort;
  const sort = filter[selected_make_id]?.sort;
  if (sort){
    const sortedModels = _OrderBy(Object.entries(modelsObject), ([key]) => model_sort.indexOf(Number(key)));
    const result = sortedModels.map(([key, value]) => ({ id: key ,value:key ,text: value }));
    return result;
  }
  return _OrderBy(
      Object.entries(modelsObject).map(([key, value]) => {
        return {
          id: key,
          value: key,
          text: value,
        };
      }),
      ["text"]
  );
});

function filterClosed() {
  showFilter.value = false;
  showForm.value = true;
}

function finishFilter() {
  showFilter.value = false;
  showForm.value = true;
  router.push({
    path: matSelectUri,
    query: {
      make: selectedProduct.value.make,
      year: selectedProduct.value.year,
      model: selectedProduct.value.model,
      sku: queriedProducts.value[0].sku.split("-")[1],
    },
  });
}

async function submitFilter() {
  setQueriedProducts([]);
  showFilter.value = false;
  selectedProduct.value.meta = {};
  if (_IsEmpty(selectedProduct.value.year) && _IsEmpty(selectedProduct.value.model)) {
    try {
      const data = await GqlGetMakeById({make: selectedProduct.value.make});

      router.push({
        path: data?.make?.uri || "/",
      });
      filterPop.value = false;
    } catch (error) {
      console.log(error);
    }
    return;
  }

  if (!_IsEmpty(selectedProduct.value.make) && !_IsEmpty(selectedProduct.value.year) && _IsEmpty(selectedProduct.value.model)) {
    modelSelect.value.menu = true;
    modelSelect.value.isFocused = true;
    return;
  }
  try {
    waitForQuery.value = true;

    const data = await findCar();
    waitForQuery.value = false;

    setQueriedProducts(data?.value?.products.nodes);
  } catch (error) {
    console.log(error);
  }
  waitForQuery.value = false;
  if (queriedProducts.value.length > 1) {
    showForm.value = true;
    showFilter.value = true;
  } else {
    setCarFilterPopup(false);
    //globalStore.setFilteredProducts(queriedProducts);
    router.push({
      path: matSelectUri || "/",
      query: {
        make: selectedProduct.value.make,
        year: selectedProduct.value.year,
        model: selectedProduct.value.model,
        sku: queriedProducts.value[0].sku.split("-")[1],
      },
    });
    filterPop.value = false;
  }
}


function mobileSelect(type = '') {

  if (window.innerWidth < 800) {

    cascaderValue.value = selectedProduct.value.model || selectedProduct.value.year || selectedProduct.value.make;
    showPop.value = true;
    if (!type) {
      cascaderValue.value = "";
      onChange({value: cascaderValue.value, tabIndex: 0});
    }

    const filterTabsWrap = document.querySelector('.van-tabs__nav');
    const filterTabs = filterTabsWrap?.querySelectorAll('.van-tab');

    if (type == 'make') {
      if (filterTabs && filterTabs.length > 0) {
        filterTabs[0].click();
      }

      cascaderValue.value = selectedProduct.value.make || null;
      if (selectedProduct.value.make) {
        placeholder.value = "שנה";

      } else {
        placeholder.value = "יצרן";

      }

    }
    if (type == 'year') {
      if (filterTabs && filterTabs.length > 0) {
        filterTabs[1].click();
      }

      placeholder.value = "שנה";
      cascaderValue.value = selectedProduct.value.model || selectedProduct.value.year || selectedProduct.value.make;
      // onChange({value: cascaderValue.value, tabIndex: 0});
    }
    if (type == 'model') {
      if (filterTabs && filterTabs.length > 0) {
        filterTabs[2].click();
      }
      cascaderValue.value = selectedProduct.value.model || selectedProduct.value.year || selectedProduct.value.make;
      placeholder.value = "דגם";
      // onChange({value: cascaderValue.value, tabIndex: 1});
    }
    setTimeout(() => {
      const filterTabsWrap = document.querySelector('.van-tabs__nav');
      const filterTabs = filterTabsWrap?.querySelectorAll('.van-tab');
      if (filterTabs && filterTabs.length > 0) {
        if (type == 'make') {
          filterTabs[0].click();
        }
        if (type == 'year') {
          filterTabs[1].click();
        }
        if (type == 'model') {
          filterTabs[2].click();
        }
      }
    }, 300);
  }
}

async function findCar() {
  let groupedYears = [parseInt(selectedProduct.value.year)];
  if (groupedYears == 1960){
    groupedYears = [1960,916];
  }
  const {data} = await useAsyncGql("getCar", {
    model: parseInt(selectedProduct.value.model),
    year: groupedYears,
    matType: 21
  });
  return data;
}

const customFilter = (item: string, query: string) => {
  return item.toLowerCase().indexOf(query.toLowerCase()) == 0;
};
</script>
<style lang="scss">

.form_input {
  img {
    @media screen and (max-width: 800px) {
      max-width: 40px;
    }
  }

  input {
    font-size: 16px;
    font-weight: 700;

  }
}

.v-field {
  &--focused {
    .v-autocomplete__selection {
      visibility: hidden;
      display: none;
    }
  }

  .v-autocomplete__selection {
    max-width: max-content !important;
  }
}

.van-tabs {
  &__track {
    direction: ltr;
  }
}

.van-popup {
  &--bottom {
    height: 100%;
  }
}

.v-select {
  .v-select__selection-text {
    font-size: 20px;
    font-weight: 700;
  }

  @media screen and (max-width: 800px) {
    .v-field {
      align-items: center;
    }
    .v-select__selection-text {
      font-weight: 600;
      font-size: 12px;
      text-overflow: initial;
      overflow: visible;
    }
    .v-field__outline {
      --v-field-border-width: 0;

    }
  }
}

.v-autocomplete {
  .v-field {
    &__input {
      cursor: pointer !important;

    }
  }

  .v-input__control {
    min-width: 200px;
  }

  &__selection {
    &:hover {
      color: var(--red);
    }

    &-text {
      font-size: 16px;
      font-weight: 700;
      @media screen and (max-width: 800px) {

        overflow: visible!important;
        text-overflow: initial!important;
      }
    }
  }

  ::placeholder {
    color: #000 !important;
    opacity: 1 !important; /* Firefox */
    font-weight: 600;
    font-size: 20px;
    @media screen and (max-width: 800px) {
      font-size: 18px;
    }
  }
}

.van-cascader {
  &__title {
    font-size: 20px;
  }

  &__tab {
    font-size: 20px;
  }

  &__option {
    border-bottom: 1px solid #eee;
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
  }

  &__options {
    height: calc(100vh - 200px);
    margin-bottom: 50px;
  }

  &__option {
    font-size: 18px;
    justify-content: flex-end;
  }

  .van-tabs__wrap {
    box-shadow: 0px 5px 13px -9px #000;
  }
}

.v-list-item {
  &__overlay {
    background: none;

    &:hover {
      background: none;
      color: var(--red);
    }
  }

  &-title {
    font-weight: 600 !important;

    &:hover {
      color: var(--red);
    }
  }
}

.form_submit {
  button {
    font-size: 24px;
    font-weight: 700;
    @media screen and (max-width: 800px) {
      font-size: 18px;
      line-height: 36px;
    }
  }
}

.van-sticky--fixed .car-filter {
  .v-input__prepend {
    margin-left: 5px;
    padding: 0;
    padding-bottom: 5px;
    align-items: flex-end;
  }

  .v-autocomplete__selection {
    &-text {
      font-size: 17px;
      overflow: visible;
    }
  }

  .v-input__control {
    min-width: 80px;
  }

  form {
    input {
      font-size: 17px;
      height: 100%;
    }
  }

  @media screen and (max-width: 800px) {
    .v-autocomplete__selection{
      img{
        display: none;
      }
    }
    .v-icon {
      width: 10px;
      margin-left: 3px;
    }
    .v-input--density-default.v-input--plain-underlined .v-input__prepend {
      padding-top: 0;
      align-self: center;
      padding-bottom: 0;
    }
    .v-select__menu-icon {
      margin-inline-start: 0px;
      font-size: 20px;
    }
    .v-autocomplete--single:not(.v-autocomplete--selection-slot).v-text-field input {
      top: 0;
      height: 30px;
    }
    form {
      input {
        font-size: 10px;
        height: 100%;

        &::placeholder {
          @media screen and (max-width: 800px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .car-filter__inner {
    padding: 0 20px 0 0;
    @media screen and (max-width: 800px) {
      padding: 0;
    }

    .form_submit {
      button {
        @media screen and (max-width: 800px) {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
}

.bottom-buttons {
  width: 100%;
  position: relative;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit;
}
.help-cascader{
  font-size: 15px;
  color: var(--red);
  a{
    color: var(--red);
    text-decoration: underline;
  }
}
</style>
